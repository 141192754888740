import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Thursday 26 May 2022 we will be making the following changes to our range</p>
    <p><strong>Summary of changes</strong></p>
    <p>We have introduced a range of bespoke products for International Residential (INTL) customers at 60%, 70% and 75% LTV and these can be found within the Mortgage Rate Sheet clearly labelled as International Residential.</p>
    <p>There is a range of International Residential products for all new business customers and a range for existing International Residential mortgage customers to include those borrowing more or rate switching.</p>
    <p>Both ranges will consist of 2 year fixed, 5 year fixed and 2 year tracker rate offerings with various booking fees attached.</p>
    <p>There are no changes to any other interest rates or to fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 26 May 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article